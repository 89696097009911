/* You can add global styles to this file, and also import other style files */

.background {
    background-image: url(./assets/background.jpg);
    background-repeat: repeat-y;
    background-position: center;
   
    
}

.background_home{
    background-color: white;
    background-repeat: repeat-y;
    background-position: center;
}
img {
    max-width: 100%;
    } 


    .voltar-ao-topo {
        position: fixed;
        bottom: 5em;
        right: 2em;
        text-decoration: none;
        color: white;
        background-color: rgba(17, 15, 133, 0.6);  
        font-size: 12px;    
        padding: 1em;
        padding-bottom: 10px;
        display: none;
        border-radius: 50%;
        border: 0;
      }
      .voltar-ao-topo:hover {
        background-color: rgba(17, 15, 133, 0.6);
      }

     